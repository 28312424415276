import { useNavigate, useParams } from "react-router-dom";
import "./TemplateDay.css";
import { useCallback, useEffect, useState } from "react";
import TitleBar, {
  TitleBarBackBtn,
  TitleBarText,
} from "../../../TitleBar/TitleBar";
import Content from "../../../Content/Content";
import { showSpinner } from "../../../Spinner/Spinner";
import session from "../../../../Session.service";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import IconSelector from "../../../IconSelector/IconSelector";
import ColorSelector from "../../../ColorSelector/ColorSelector";
import ObjectID from "bson-objectid";
import * as Yup from "yup";
import DurationSelector from "../../../DurationSelector/DurationSelector";
import TimeSelector from "../../../TimeSelector/TimeSelector";

export default function TemplateDay() {
  const { id } = useParams();
  const [original, setOriginal] = useState();
  const navigate = useNavigate();

  const load = useCallback(async () => {
    showSpinner(true);
    if (id === "new") {
      setOriginal({ name: "", icon: null, color: -1, routines: [] });
    } else {
      setOriginal(await session.getTemplateDay(id));
    }
    showSpinner(false);
  }, [id]);

  useEffect(() => {
    load();
  }, [load]);

  async function onSubmit(values, { setFieldError }) {
    showSpinner(true);
    try {
      if (id === "new") {
        await session.createTemplateDay(values);
      } else {
        await session.updateTemplateDay(id, values);
      }
      navigate("/templates");
    } catch (e) {
      setFieldError("_", "Erreur inattendue : " + e.message);
      console.error(e);
    } finally {
      showSpinner(false);
    }
  }

  function backToTemplates() {
    navigate("/templates");
  }

  return (
    <>
      <TitleBar>
        <TitleBarBackBtn onClick={backToTemplates} />
        <TitleBarText>
          Modèle de journée
          {id === "new" ? (
            " - Nouveau"
          ) : (
            <>{original && <> - {original.name}</>}</>
          )}
        </TitleBarText>
      </TitleBar>
      {original && (
        <Formik
          initialValues={{
            name: original.name,
            icon: original.icon,
            color: original.color,
            routines: original.routines,
            _: null,
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required("ne peut pas être vide"),
            icon: Yup.string().nullable(),
            color: Yup.number().nullable(),
            routines: Yup.array().of(
              Yup.object().shape({
                name: Yup.string().required("ne peut pas être vide"),
                icon: Yup.string().nullable(),
                time: Yup.number().nullable(),
                duration: Yup.number(),
                tasks: Yup.array()
                  .of(
                    Yup.object().shape({
                      name: Yup.string().required("ne peut pas être vide"),
                      icon: Yup.string().nullable(),
                    })
                  )
                  .min(1, "une routine doit contenir au moins une tâche")
                  .required("&&&"),
              })
            ),
          })}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, values, errors }) => (
            <Form>
              <Content>
                <div>
                  <label htmlFor="name">Nom</label>
                  <Field
                    autoComplete="off"
                    type="text"
                    name="name"
                    id="name"
                    style={{ width: "400px", display: "block" }}
                    placeholder="ex: Fin de semaine"
                    autoFocus={true}
                  />
                  <ErrorMessage name="name" component="div" className="err" />
                </div>

                <div>
                  <label htmlFor="color">Couleur</label>
                  <Field as={ColorSelector} id="color" name="color" />
                </div>

                <div>
                  <label htmlFor="icon">Icône</label>
                  <Field as={IconSelector} id="icon" name="icon" />
                </div>

                <div>
                  <label>Routines</label>
                  <FieldArray
                    name="routines"
                    render={(arrayHelpers) => (
                      <>
                        {values.routines && values.routines.length > 0 ? (
                          <div className="tday-routines-container">
                            {values.routines.map((routine, index) => (
                              <TemplateDayRoutine
                                key={routine.id}
                                routine={routine}
                                index={index}
                                arrayHelpers={arrayHelpers}
                                errors={
                                  errors.routines &&
                                  errors.routines.length >= index
                                    ? errors.routines[index]
                                    : null
                                }
                              />
                            ))}
                          </div>
                        ) : (
                          <p style={{ color: "#999", marginBottom: "10px" }}>
                            Aucune routine pour l'instant.
                          </p>
                        )}

                        <button
                          type="button"
                          onClick={() =>
                            arrayHelpers.push({
                              id: ObjectID().toHexString(),
                              name: "",
                              icon: null,
                              time: 48,
                              duration: 2,
                              tasks: [],
                            })
                          }
                        >
                          <img
                            src="/plus.png"
                            alt="ajouter routine"
                            className="btnimg"
                          />
                          Ajouter une routine
                        </button>
                      </>
                    )}
                  />
                </div>

                <div
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                  }}
                >
                  <button type="button" onClick={backToTemplates}>
                    <img src="/cross.png" alt="Annuler" className="btnimg" />
                    Annuler
                  </button>
                  <button
                    className="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    <img src="/save.png" alt="Effacer" className="btnimg" />
                    Enregistrer
                  </button>
                </div>

                <ErrorMessage name="_" component="div" className="err" />
              </Content>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
}

function TemplateDayRoutine({ routine, index, arrayHelpers, errors }) {
  return (
    <div className="tday-routine">
      <div>
        <label htmlFor={"name" + routine.id}>Nom</label>
        <Field
          autoComplete="off"
          type="text"
          name={"routines." + index + ".name"}
          id={"name" + routine.id}
          style={{ width: "400px", display: "block" }}
          placeholder="ex: Matin"
        />
        <ErrorMessage
          name={"routines[" + index + "].name"}
          component="div"
          className="err"
        />
      </div>

      <div>
        <label htmlFor={"icon" + routine.id}>Icône</label>
        <Field
          as={IconSelector}
          id={"icon" + routine.id}
          name={"routines." + index + ".icon"}
        />
      </div>

      <div>
        <label htmlFor={"time" + routine.id}>Début</label>
        <Field
          as={TimeSelector}
          id={"time" + routine.id}
          name={"routines." + index + ".time"}
          style={{ width: "400px" }}
        />
        <ErrorMessage
          name={"routines." + index + ".time"}
          component="div"
          className="err"
        />
      </div>

      <div>
        <label htmlFor={"duration" + routine.id}>Durée</label>
        <Field
          as={DurationSelector}
          id={"duration" + routine.id}
          name={"routines." + index + ".duration"}
          style={{ width: "400px" }}
        />
        <ErrorMessage
          name={"routines." + index + ".duration"}
          component="div"
          className="err"
        />
      </div>

      <div>
        <label>Tâches</label>
        <FieldArray
          name={"routines." + index + ".tasks"}
          render={(tasksArrayHelpers) => (
            <>
              {routine.tasks && routine.tasks.length > 0 ? (
                <div className="tday-tasks-container">
                  {routine.tasks.map((task, taskIndex) => (
                    <div key={task.id} className="tday-task">
                      <div className="tday-task-updown-container noselect">
                        <button
                          className="tday-task-updown"
                          type="button"
                          onClick={() => {
                            tasksArrayHelpers.swap(taskIndex, taskIndex - 1);
                          }}
                          style={{
                            visibility: taskIndex > 0 ? "visible" : "hidden",
                          }}
                        >
                          <img
                            src="/up.png"
                            alt="monter"
                            className="btnimg-solo"
                          />
                        </button>

                        <button
                          type="button"
                          className="tday-task-updown"
                          onClick={() => {
                            tasksArrayHelpers.swap(taskIndex, taskIndex + 1);
                          }}
                          style={{
                            visibility:
                              taskIndex < routine.tasks.length - 1
                                ? "visible"
                                : "hidden",
                          }}
                        >
                          <img
                            src="/down.png"
                            alt="descendre"
                            className="btnimg-solo"
                          />
                        </button>
                      </div>

                      <div>
                        <label htmlFor={"name" + task.id}>
                          Nom{" "}
                          <ErrorMessage
                            name={`routines[${index}].tasks[${taskIndex}].name`}
                            component="span"
                            className="err"
                          />
                        </label>
                        <Field
                          autoComplete="off"
                          type="text"
                          name={`routines.${index}.tasks.${taskIndex}.name`}
                          id={"name" + task.id}
                          style={{ width: "250px", display: "block" }}
                          placeholder="ex: Brosser dents"
                        />
                      </div>

                      <div style={{ margin: "auto" }}>
                        <Field
                          as={IconSelector}
                          id={"icon" + task.id}
                          name={`routines.${index}.tasks.${taskIndex}.icon`}
                          compact={true}
                        />
                      </div>

                      <div style={{ margin: "auto" }}>
                        <button
                          type="button"
                          className="danger"
                          onClick={() => tasksArrayHelpers.remove(taskIndex)}
                        >
                          <img
                            src="/trash.png"
                            alt="supprimer routine"
                            className="btnimg-solo"
                          />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p style={{ color: "#999", marginBottom: "10px" }}>
                  Aucune tâche pour l'instant.
                </p>
              )}

              <button
                type="button"
                onClick={() =>
                  tasksArrayHelpers.push({
                    id: ObjectID().toHexString(),
                    name: "",
                    icon: null,
                  })
                }
              >
                <img src="/plus.png" alt="ajouter tâche" className="btnimg" />
                Ajouter une tâche
              </button>
              {errors && typeof errors.tasks === "string" && (
                <ErrorMessage
                  name={`routines[${index}].tasks`}
                  component="div"
                  className="err"
                />
              )}
            </>
          )}
        />
      </div>

      <div>
        <button
          style={{ marginTop: "10px", marginBottom: "10px" }}
          type="button"
          className="danger"
          onClick={() => arrayHelpers.remove(index)}
        >
          <img src="/trash.png" alt="supprimer routine" className="btnimg" />
          Supprimer la routine
        </button>
      </div>
    </div>
  );
}
