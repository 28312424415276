import { useFormikContext } from "formik";
import "./TimeSelector.css";
import { timeUnitToTime } from "../../utils.mjs";

export default function TimeSelector(props) {
  const { setFieldValue } = useFormikContext();

  function changeValue(val) {
    setFieldValue(props.name, Math.min(Math.max(0, val), 95));
  }

  const displayValue = timeUnitToTime(props.value).replace(":", "h");

  return (
    <div className="timsel-container" style={{ ...props.style }}>
      <button
        type="button"
        className="timsel-leftbtn"
        onClick={() => changeValue(props.value - 4)}
      >
        -1h
      </button>
      <button
        type="button"
        className="timsel-leftbtn"
        onClick={() => changeValue(props.value - 1)}
      >
        -15
      </button>
      <input
        className="timsel-input"
        autoComplete="off"
        type="text"
        readOnly={true}
        id={props.id}
        value={displayValue}
      />
      <button
        type="button"
        className="timsel-rightbtn"
        onClick={() => changeValue(props.value + 1)}
      >
        +15
      </button>
      <button
        type="button"
        className="timsel-rightbtn"
        onClick={() => changeValue(props.value + 4)}
      >
        +1h
      </button>
    </div>
  );
}
