import { useEffect, useState } from "react";
import "./DropMenu.css";

export function DropMenuBtn({ children, className = "" }) {
  const [menuVisible, setMenuVisible] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

  function onClick(evt) {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const buttonWidth = evt.target.width;
    const buttonHeight = evt.target.height;
    const buttonX = evt.target.offsetLeft + buttonWidth / 2;
    const buttonY = evt.target.offsetTop + buttonHeight / 2;
    const menuWidth = evt.target.nextSibling.clientWidth;
    const menuHeight = evt.target.nextSibling.clientHeight;

    const x = buttonX < windowWidth / 2 ? buttonX : buttonX - menuWidth;
    const y =
      buttonY < windowHeight / 2
        ? buttonY + buttonHeight / 2
        : buttonY - menuHeight - buttonHeight / 2;
    setMenuPosition({ x, y });
    setMenuVisible(!menuVisible);
  }
  useEffect(() => {
    function handleDocumentClick() {
      setMenuVisible(false);
    }
    document.addEventListener("mouseup", handleDocumentClick);
    return () => {
      document.removeEventListener("mouseup", handleDocumentClick);
    };
  }, []);
  return (
    <>
      <img
        draggable={false}
        onClick={onClick}
        src="/menu.png"
        alt="menu"
        className={
          className +
          " dropmenu-btn noselect " +
          (menuVisible ? "dropmenu-btn-opened" : "")
        }
      />
      <DropMenu visible={menuVisible} position={menuPosition}>
        {children}
      </DropMenu>
    </>
  );
}
export function DropMenu({ children, position, visible }) {
  const cssClass = visible ? "dropmenu-visible" : "dropmenu-hidden";
  const cssStyle =
    position.x > 0 && position.y > 0
      ? { left: position.x, top: position.y }
      : { left: 0, top: 0, visibility: "hidden" };
  return (
    <div className={"dropmenu " + cssClass} style={cssStyle}>
      {children}
    </div>
  );
}
export function DropMenuItem({ label, onClick, icon }) {
  return (
    <div onClick={onClick} className="dropmenu-item noselect">
      {icon ? (
        <img
          draggable={false}
          src={icon}
          alt={label}
          className="dropmenu-item-icon"
        />
      ) : (
        <div className="dropmenu-item-icon"></div>
      )}
      <div className="dropmenu-item-label">{label}</div>
    </div>
  );
}
export function DropMenuSeparator() {
  return <div className="dropmenu-separator"></div>;
}
