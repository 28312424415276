export default function TimeTimer({ size, value, max = 100 }) {
  return (
    <svg height={size} width={size}>
      <circle r={size / 2} cx={size / 2} cy={size / 2} fill="#422" />
      <circle
        r={size / 4}
        cx={size / 2}
        cy={size / 2}
        fill="none"
        stroke="#d33"
        strokeWidth={size / 2}
        strokeDasharray={
          "calc(3.1415 * " +
          (size / 2 - (value / max) * (size / 2)) +
          ") calc(3.1415 * " +
          (value / max) * (size / 2) +
          ")"
        }
        transform={
          "scale(-1 1) translate(-" +
          size +
          " 0) rotate(-90 " +
          size / 2 +
          "," +
          size / 2 +
          ")"
        }
      />
    </svg>
  );
}
