import { useFormikContext } from "formik";
import "./DurationSelector.css";

export default function DurationSelector(props) {
  const { setFieldValue } = useFormikContext();

  function changeValue(val) {
    setFieldValue(props.name, Math.min(Math.max(1, val), 32));
  }

  return (
    <div className="dursel-container" style={{ ...props.style }}>
      <button
        type="button"
        className="dursel-leftbtn"
        onClick={() => changeValue(props.value - 1)}
      >
        -15
      </button>
      <input
        className="dursel-input"
        autoComplete="off"
        type="text"
        readOnly={true}
        id={props.id}
        value={props.value * 15 + " mins"}
      />
      <button
        type="button"
        className="dursel-rightbtn"
        onClick={() => changeValue(props.value + 1)}
      >
        +15
      </button>
    </div>
  );
}
