import { useState } from "react";

export function todayYYYYMMDD() {
  return dateToYYYYMMDD(new Date());
}
export function dateToYYYYMMDD(date, UTC = false) {
  if (UTC) {
    return (
      date.getUTCFullYear().toString() +
      "-" +
      (date.getUTCMonth() + 1).toString().padStart(2, "0") +
      "-" +
      date.getUTCDate().toString().padStart(2, "0")
    );
  } else {
    return (
      date.getFullYear().toString() +
      "-" +
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      date.getDate().toString().padStart(2, "0")
    );
  }
}
export function currentTimeUnit(precise = false) {
  const now = new Date();

  const seconds =
    now.getHours() * 60 * 60 + now.getMinutes() * 60 + now.getSeconds();

  if (precise) return seconds / 60 / 15;
  else return Math.floor(seconds / 60 / 15);
}
export function timeUnitToTime(unit) {
  const hours = Math.floor(unit / 4);
  const minutes = 15 * (unit - hours * 4);
  return hours + ":" + minutes.toString().padStart(2, "0");
}
export function dateOnly(date = new Date(), UTC = false) {
  if (UTC) {
    return new Date(
      Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
    );
  } else {
    return new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
  }
}
export function hsv2rgb(h, s, v) {
  let f = (n, k = (n + h / 60) % 6) =>
    v - v * s * Math.max(Math.min(k, 4 - k, 1), 0);
  return [f(5), f(3), f(1)];
}
export function rgb2hsv(r, g, b) {
  let v = Math.max(r, g, b),
    c = v - Math.min(r, g, b);
  let h =
    c && (v === r ? (g - b) / c : v === g ? 2 + (b - r) / c : 4 + (r - g) / c);
  return [60 * (h < 0 ? h + 6 : h), v && c / v, v];
}
export function rgbaFromColorCode(code, bg = false) {
  if (isNaN(code) || code == null || code < -3 || code > 11) code = -3;
  const o = bg ? 0.1 : 1;
  if (code === -3) return "rgba(240,240,240," + o + ")";
  if (code === -2) return "rgba(160,160,160," + o + ")";
  if (code === -1) return "rgba(80,80,80," + o + ")";
  const rgb = hsv2rgb(code * 30, 1, 1);
  const r = rgb[0] * 255;
  const g = rgb[1] * 255;
  const b = rgb[2] * 255;
  return `rgba(${r},${g},${b},${o})`;
}
export function useForceUpdate() {
  // eslint-disable-next-line
  const [value, setValue] = useState(0);
  return () => setValue((value) => value + 1);
}
export function monthName(m) {
  switch (m) {
    case 1:
      return "janvier";
    case 2:
      return "février";
    case 3:
      return "mars";
    case 4:
      return "avril";
    case 5:
      return "mai";
    case 6:
      return "juin";
    case 7:
      return "juillet";
    case 8:
      return "août";
    case 9:
      return "septembre";
    case 10:
      return "octobre";
    case 11:
      return "novembre";
    case 12:
      return "décembre";
    default:
      return "ERREUR";
  }
}
