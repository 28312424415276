import axios from "axios";
import { dateToYYYYMMDD } from "./utils.mjs";

const baseURL =
  process.env.NODE_ENV === "development"
    ? "http://localhost/api/"
    : "https://fostr.joashby.net/api/";

class Session {
  //#region General
  isAuth = false;
  http = axios.create({ baseURL });

  saveAuthCode(code) {
    localStorage.setItem("authCode", code);
  }
  async checkAuthCode() {
    const code = localStorage.getItem("authCode") || "";
    if (!code) return false;
    try {
      await this.attemptLogin(code);
      return true;
    } catch (e) {
      return false;
    }
  }
  forceLogout() {
    this.isAuth = false;
    localStorage.removeItem("authCode");
  }

  async attemptLogin(code) {
    const res = await this.http.post(`admin/passcode/${code}`);
    if (res.data.valid) {
      this.saveAuthCode(code);
      this.isAuth = true;
      return true;
    }
    throw Error("Le code que vous avez entré n'est pas valide.");
  }

  //#endregion

  //#region Icons

  getIconURL(id) {
    if (!id) return "";
    return baseURL + "user/icons/" + id;
  }

  async getAllIcons() {
    const res = await this.http.get(`/admin/icons`);
    return res.data.icons
      .filter((icon) => !icon.deleted)
      .map((icon) => icon.id)
      .reverse();
  }

  async addIcon(file) {
    const formData = new FormData();
    formData.append("image", file);
    const res = await this.http.post(`/admin/icons`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return res.data;
  }

  async deleteIcon(id) {
    return (await this.http.delete(`/admin/icons/${id}`)).data;
  }

  //#endregion

  //#region Stars

  async getStars() {
    const res = await this.http.get(`/user/stars`);
    return res.data.stars;
  }

  async getStarsExt() {
    const res = await this.http.get(`/admin/stars`);
    return res.data;
  }

  async addStars(amount) {
    const res = await this.http.post(`/admin/stars`, { amount });
    return res.data;
  }

  async deleteStars(id) {
    const res = await this.http.delete(`/admin/stars/${id}`);
    return res;
  }

  //#endregion

  //#region Templates

  async getTemplateDays() {
    const res = await this.http.get(`/admin/templates/days`);
    return res.data.template_days;
  }
  async getTemplateDay(id) {
    const res = await this.http.get(`/admin/templates/days/${id}`);
    return res.data;
  }
  async createTemplateDay(data) {
    const res = await this.http.post(`/admin/templates/days`, data);
    return res.data;
  }
  async updateTemplateDay(id, data) {
    const res = await this.http.put(`/admin/templates/days/${id}`, data);
    return res.data;
  }
  async applyTemplateDay(id) {
    return await this.http.post(`/admin/templates/days/${id}/apply`);
  }
  async deleteTemplateDay(id) {
    return await this.http.delete(`/admin/templates/days/${id}`);
  }

  async getTemplateEvents() {
    const res = await this.http.get(`/admin/templates/events`);
    return res.data.template_events;
  }
  async getTemplateEvent(id) {
    const res = await this.http.get(`/admin/templates/events/${id}`);
    return res.data;
  }
  async createTemplateEvent(data) {
    const res = await this.http.post(`/admin/templates/events`, data);
    return res.data;
  }
  async updateTemplateEvent(id, data) {
    const res = await this.http.put(`/admin/templates/events/${id}`, data);
    return res.data;
  }
  async applyTemplateEvent(id) {
    return await this.http.post(`/admin/templates/events/${id}/apply`);
  }
  async deleteTemplateEvent(id) {
    return await this.http.delete(`/admin/templates/events/${id}`);
  }

  //#endregion

  async getDay(date = new Date()) {
    const day = dateToYYYYMMDD(date, true);
    const res = await this.http.get(`/user/days/${day}`);
    return res.data;
  }

  async getMonth(year, month) {
    const year_month =
      year.toString().padStart(4, "0") +
      "-" +
      month.toString().padStart(2, "0");
    const res = await this.http.get(`/user/calendar/${year_month}`);
    return res.data;
  }

  async setTaskStatus(id, status) {
    const res = await this.http.patch(
      `/admin/tasks/${id}/status/${status ? 1 : 0}`
    );
    return res.data;
  }

  async setRoutineStatus(id, status) {
    const res = await this.http.patch(`/admin/routines/${id}/status/${status}`);
    return res.data;
  }

  async autofillMonth(year, month, week, weekend) {
    const year_month =
      year.toString().padStart(4, "0") +
      "-" +
      month.toString().padStart(2, "0");
    return await this.http.post(`/admin/calendar/${year_month}/autofill`, {
      week,
      weekend,
    });
  }

  async resetDay(date) {
    const ymd = dateToYYYYMMDD(date, true);
    return await this.http.patch(`/admin/days/${ymd}/reset`);
  }

  async dayApplyTemplate(date, id) {
    const ymd = dateToYYYYMMDD(date, true);
    return await this.http.patch(`/admin/days/${ymd}/template/${id}`);
  }

  async deleteEvent(id) {
    return await this.http.delete(`/admin/events/${id}`);
  }

  async createEvent(data) {
    return (await this.http.post(`/admin/events`, data)).data;
  }
}

const session = new Session();
await session.checkAuthCode();

export default session;
