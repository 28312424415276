import { getIn, useFormikContext } from "formik";
import "./TemplateEventSelector.css";
import { useCallback, useEffect, useState } from "react";
import session from "../../Session.service";
import Popup from "../Popup/Popup";
import { showSpinner } from "../Spinner/Spinner";

export default function TemplateEventSelector(props) {
  const { setFieldValue, values } = useFormikContext();
  const [name, setName] = useState("");
  const fieldValue = getIn(values, props.name);
  const [popupVisible, setPopupVisible] = useState(false);
  const [templateEvents, setTemplateEvents] = useState(null);

  function changeValue(val) {
    setFieldValue(props.name, val);
    setPopupVisible(false);
  }

  const refreshTemplateName = useCallback(async () => {
    if (!fieldValue) {
      setName("");
    } else {
      const tevent = await session.getTemplateEvent(fieldValue);
      setName(tevent.name);
    }
  }, [setName, fieldValue]);

  useEffect(() => {
    refreshTemplateName();
  }, [fieldValue, refreshTemplateName]);

  async function openPopup() {
    showSpinner(true);
    setTemplateEvents(await session.getTemplateEvents());
    setPopupVisible(true);
    showSpinner(false);
  }

  function closePopup() {
    setPopupVisible(false);
  }

  return (
    <>
      <Popup visible={popupVisible} onDismiss={closePopup}>
        <h3>Sélectionner un modèle d'évènement</h3>

        {templateEvents && (
          <div className="tevtsel-list">
            {templateEvents.map((tevent) => (
              <TemplateEvent
                key={tevent.id}
                icon={tevent.icon}
                onClick={() => changeValue(tevent.id)}
              >
                {tevent.name}
              </TemplateEvent>
            ))}
          </div>
        )}

        <button type="button" onClick={closePopup}>
          Annuler
        </button>
      </Popup>
      <div className="tevtsel-container" style={{ ...props.style }}>
        <input
          className="tevtsel-input"
          autoComplete="off"
          type="text"
          readOnly={true}
          id={props.id}
          value={name}
        />
        <button type="button" className="tevtsel-rightbtn" onClick={openPopup}>
          <img src="/search.png" alt="sélectionner" className="btnimg-solo" />
        </button>
        <button
          type="button"
          className="tevtsel-rightbtn"
          onClick={() => changeValue(null)}
        >
          <img src="/cross.png" alt="effacer" className="btnimg-solo" />
        </button>
      </div>
    </>
  );
}

function TemplateEvent({ icon, children, onClick }) {
  return (
    <div className="tevtsel-item noselect" onClick={onClick}>
      {icon ? (
        <img
          draggable={false}
          src={session.getIconURL(icon)}
          alt=""
          className="tevtsel-item-icon"
        />
      ) : (
        <div className="tevtsel-item-icon" />
      )}
      <div className="tevtsel-item-name">{children}</div>
    </div>
  );
}
