import "./Login.css";
import session from "../../../Session.service";
import ContentCentered from "../../Content/ContentCentered";
import Content from "../../Content/Content";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { showSpinner } from "../../Spinner/Spinner";

export default function Login({ onLogin }) {
  async function onSubmit(values, { setFieldError }) {
    showSpinner(true);
    try {
      await session.attemptLogin(values.code);
      onLogin();
    } catch (e) {
      setFieldError("code", "code invalide - " + e.message);
    } finally {
      showSpinner(false);
    }
  }

  return (
    <>
      <ContentCentered style={{ gap: "20px" }}>
        <Formik
          initialValues={{ code: "" }}
          validate={(values) => {
            const errors = {};
            if (!values.code) {
              errors.code = "requis";
            }
            return errors;
          }}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <Content>
                <h3 style={{ alignSelf: "center" }}>Authentification</h3>
                <div style={{ alignSelf: "stretch" }}>
                  <Field
                    type="password"
                    name="code"
                    style={{ width: "250px" }}
                    placeholder="Code"
                    autoFocus={true}
                  />
                  <ErrorMessage
                    name="code"
                    component="div"
                    className="err"
                    style={{
                      width: "250px",
                    }}
                  />
                </div>
                <button
                  className="primary"
                  type="submit"
                  disabled={isSubmitting}
                  style={{ display: "block", alignSelf: "center" }}
                >
                  Connexion
                </button>
              </Content>
            </Form>
          )}
        </Formik>
      </ContentCentered>
    </>
  );
}
