import { useNavigate, useParams } from "react-router-dom";
import "./TemplateEvent.css";
import { useCallback, useEffect, useState } from "react";
import TitleBar, {
  TitleBarBackBtn,
  TitleBarText,
} from "../../../TitleBar/TitleBar";
import Content from "../../../Content/Content";
import { showSpinner } from "../../../Spinner/Spinner";
import session from "../../../../Session.service";
import { Formik, Form, Field, ErrorMessage } from "formik";
import DurationSelector from "../../../DurationSelector/DurationSelector";
import IconSelector from "../../../IconSelector/IconSelector";

export default function TemplateEvent() {
  const { id } = useParams();
  const [original, setOriginal] = useState();
  const navigate = useNavigate();

  const load = useCallback(async () => {
    showSpinner(true);
    if (id === "new") {
      setOriginal({ name: "", duration: 2, icon: null });
    } else {
      setOriginal(await session.getTemplateEvent(id));
    }
    showSpinner(false);
  }, [id]);

  useEffect(() => {
    load();
  }, [load]);

  async function onSubmit(values, { setFieldError }) {
    showSpinner(true);
    try {
      if (id === "new") {
        await session.createTemplateEvent(values);
      } else {
        await session.updateTemplateEvent(id, values);
      }
      navigate("/templates");
    } catch (e) {
      setFieldError("_", "Erreur inattendue : " + e.message);
      console.error(e);
    } finally {
      showSpinner(false);
    }
  }

  function backToTemplates() {
    navigate("/templates");
  }

  return (
    <>
      <TitleBar>
        <TitleBarBackBtn onClick={backToTemplates} />
        <TitleBarText>
          Modèle d'évènement
          {id === "new" ? (
            " - Nouveau"
          ) : (
            <>{original && <> - {original.name}</>}</>
          )}
        </TitleBarText>
      </TitleBar>
      {original && (
        <Formik
          initialValues={{
            name: original.name,
            duration: original.duration,
            icon: original.icon,
            _: null,
          }}
          validate={(values) => {
            const errors = {};
            if (!values.name) {
              errors.name = "ne peut pas être vide.";
            }
            return errors;
          }}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <Content>
                <div>
                  <label htmlFor="name">Nom</label>
                  <Field
                    autoComplete="off"
                    type="text"
                    name="name"
                    id="name"
                    style={{ width: "400px", display: "block" }}
                    placeholder="ex: Promenade en vélo"
                    autoFocus={true}
                  />
                  <ErrorMessage name="name" component="div" className="err" />
                </div>

                <div>
                  <label htmlFor="duration">Durée</label>
                  <Field
                    as={DurationSelector}
                    id="duration"
                    name="duration"
                    style={{ width: "400px" }}
                  />
                  <ErrorMessage
                    name="duration"
                    component="div"
                    className="err"
                  />
                </div>

                <div>
                  <label htmlFor="icon">Icône</label>
                  <Field as={IconSelector} id="icon" name="icon" />
                  <ErrorMessage name="icon" component="div" className="err" />
                </div>

                <div
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                  }}
                >
                  <button type="button" onClick={backToTemplates}>
                    <img src="/cross.png" alt="Annuler" className="btnimg" />
                    Annuler
                  </button>
                  <button
                    className="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    <img src="/save.png" alt="Enregistrer" className="btnimg" />
                    Enregistrer
                  </button>
                </div>

                <ErrorMessage name="_" component="div" className="err" />
              </Content>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
}
