import { useEffect, useRef, useState } from "react";
import Content from "../../Content/Content";
import TitleBar, { TitleBarText } from "../../TitleBar/TitleBar";
import Resizer from "react-image-file-resizer";
import "./Icons.css";
import { showSpinner } from "../../Spinner/Spinner";
import session from "../../../Session.service";
import { getImageSize } from "react-image-size";
import Popup from "../../Popup/Popup";

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      64,
      64,
      "PNG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });

export default function Icons() {
  const inputFile = useRef(null);
  const [errMsg, setErrMsg] = useState(null);
  const [icons, setIcons] = useState(null);
  const [deletePopupVisible, setDeletePopupVisible] = useState(false);
  const [iconToDelete, setIconToDelete] = useState(null);

  async function loadIcons() {
    setIcons(await session.getAllIcons());
  }

  async function fileSelected(evt) {
    showSpinner(true);
    setErrMsg("");

    const file = inputFile.current.files[0];
    console.log(inputFile.current.files[0]);
    inputFile.current.value = "";
    console.log(inputFile.current.files[0]);
    if (!file) {
      setErrMsg("Fichier invalide.");
      showSpinner(false);
      return;
    }

    const dimensions = await getImageSize(URL.createObjectURL(file));
    if (dimensions.width !== dimensions.height) {
      setErrMsg(
        "La largeur et la hauteur de l'image doivent être identiques (carré)."
      );
      showSpinner(false);
      return;
    }

    let newFile;
    try {
      newFile = await resizeFile(file);
    } catch (e) {
      setErrMsg("Erreur de redimensionnement : " + e.message);
      console.error(e);
      showSpinner(false);
      return;
    }

    if (!newFile) {
      setErrMsg("Fichier redimensionné invalide.");
      showSpinner(false);
      return;
    }

    try {
      await session.addIcon(newFile);
    } catch (e) {
      setErrMsg("Erreur d'envoi du fichier : " + e.message);
      console.error(e);
      showSpinner(false);
      return;
    }

    await loadIcons();
    showSpinner(false);
  }

  async function deleteIcon() {
    showSpinner(true);
    await session.deleteIcon(iconToDelete);
    await loadIcons();
    closeDeletePopup();
    showSpinner(false);
  }

  useEffect(() => {
    (async () => {
      showSpinner(true);
      await loadIcons();
      showSpinner(false);
    })();
  }, []);

  function openDeletePopup(id) {
    setIconToDelete(id);
    setDeletePopupVisible(true);
  }

  function closeDeletePopup() {
    setDeletePopupVisible(false);
  }

  return (
    <>
      <Popup visible={deletePopupVisible} onDismiss={closeDeletePopup}>
        <h3>Suppression</h3>
        <p>Voulez-vous vraiment supprimer cette icône?</p>
        <img
          className="noselect"
          draggable={false}
          src={session.getIconURL(iconToDelete)}
          alt="Icône à supprimer"
        />
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            gap: "10px",
          }}
        >
          <button onClick={closeDeletePopup}>Annuler</button>
          <button onClick={deleteIcon} className="danger">
            Supprimer
          </button>
        </div>
      </Popup>
      <TitleBar>
        <TitleBarText>Icônes</TitleBarText>
      </TitleBar>
      <Content>
        {icons !== null && (
          <>
            <div>
              <input
                type="file"
                accept="image/png, image/jpeg"
                id="file"
                ref={inputFile}
                style={{ display: "none" }}
                onChange={fileSelected}
              />
              <button onClick={() => inputFile.current.click()}>
                Ajouter une icône...
              </button>
              {errMsg && (
                <div
                  className="err"
                  style={{ display: "inline", marginLeft: "20px" }}
                >
                  {errMsg}
                </div>
              )}
            </div>
            {icons.length > 0 ? (
              <div className="icons-container noselect">
                {icons.map((iconId) => (
                  <div
                    onClick={() => openDeletePopup(iconId)}
                    key={iconId}
                    className="icons-item noselect"
                    style={{
                      backgroundImage:
                        'url("' + session.getIconURL(iconId) + '")',
                    }}
                  >
                    <div className="icons-delete noselect">
                      <img
                        src="trash.png"
                        alt="supprimer"
                        draggable={false}
                        className="noselect"
                      />
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p>Aucune icône à afficher pour l'instant.</p>
            )}
          </>
        )}
      </Content>
    </>
  );
}
