import "./TitleBar.css";

export default function TitleBar({ children }) {
  return <div className="title-bar">{children}</div>;
}

export function TitleBarText({ children }) {
  return <div className="title-bar-text">{children}</div>;
}

export function TitleBarBackBtn({ onClick }) {
  return (
    <img
      draggable={false}
      className="title-bar-back-btn noselect"
      onClick={onClick}
      src="/back.png"
      alt="retour"
    />
  );
}
