import React from "react";
import ReactDOM from "react-dom/client";
import "./fonts/segoe-ui.ttf";
import "./fonts/spacemono-regular.ttf";
import "./index.css";
import App from "./App";
import Spinner from "./components/Spinner/Spinner";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Spinner />
    <App />
  </React.StrictMode>
);
