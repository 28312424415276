import Content from "../../Content/Content";
import "./Month.css";
import TitleBar, { TitleBarText } from "../../TitleBar/TitleBar";
import { useRef, useState } from "react";
import { useEffect } from "react";
import { showSpinner } from "../../Spinner/Spinner";
import session from "../../../Session.service";
import { useCallback } from "react";
import {
  dateOnly,
  dateToYYYYMMDD,
  monthName,
  rgbaFromColorCode,
} from "../../../utils.mjs";
import Popup from "../../Popup/Popup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import TemplateDaySelector from "../../TemplateDaySelector/TemplateDaySelector";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function Month() {
  const [month, setMonth] = useState(null);
  const [days, setDays] = useState(null);
  const [autofillPopupVisible, setAutofillPopupVisible] = useState(false);
  const autofillFormRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const year_month = searchParams.get("position");
  const navigate = useNavigate();

  const loadDays = useCallback(async () => {
    if (!month) return;
    showSpinner(true);
    setDays((await session.getMonth(month.year, month.month)).days);
    showSpinner(false);
  }, [month]);

  useEffect(() => {
    if (year_month) {
      const yy = parseInt(year_month.split("-")[0]);
      const mm = parseInt(year_month.split("-")[1]);
      if (!isNaN(yy) && !isNaN(mm)) {
        if (yy >= 2023 && mm >= 1 && mm <= 12) {
          setMonth({ year: yy, month: mm });
          return;
        }
      }
    }
    const now = new Date();
    const y = now.getFullYear();
    const m = now.getMonth();
    setMonth({ year: y, month: m + 1 });
    setSearchParams({
      position: y + "-" + (m + 1).toString().padStart(2, "0"),
    });
  }, [year_month, setSearchParams]);

  useEffect(() => {
    loadDays();
  }, [month, loadDays]);

  function splitArray(arr, n) {
    const res = [];
    for (let i = n; i > 0; i--) {
      res.push(arr.splice(0, Math.ceil(arr.length / i)));
    }
    return res;
  }

  function changeMonth(offset) {
    const old = new Date(Date.UTC(month.year, month.month - 1, 1));
    old.setUTCMonth(old.getUTCMonth() + offset);
    let y = old.getUTCFullYear();
    let m = old.getUTCMonth();
    if (y <= 2022) return;
    setMonth({ year: y, month: m + 1 });
    setSearchParams({
      position: y + "-" + (m + 1).toString().padStart(2, "0"),
    });
  }

  const cells = Array(42);
  if (month && days) {
    const y = month.year.toString().padStart(4, "0");
    const m = month.month.toString().padStart(2, "0");
    const firstDate = new Date(Date.parse(y + "-" + m + "-01"));
    const firstDay = firstDate.getUTCDay();
    const offset = firstDay === 0 ? -6 : 1;
    for (let i = 0; i < 42; i++) {
      const date = new Date(firstDate);
      date.setUTCDate(date.getUTCDate() - firstDay + i + offset);
      const cell = { date };
      const yyyymmdd = dateToYYYYMMDD(date, true);
      for (const day of days) {
        if (yyyymmdd === day.date) {
          cell.details = day;
          break;
        }
      }
      cells[i] = cell;
    }
  }

  function openAutofillPopup() {
    setAutofillPopupVisible(true);
  }

  function closeAutofillPopup() {
    setAutofillPopupVisible(false);
    autofillFormRef.current.resetForm();
  }

  async function autofill(values, { setFieldError }) {
    showSpinner(true);
    try {
      await session.autofillMonth(
        month.year,
        month.month,
        values.weekdayTemplate,
        values.weekendTemplate
      );
      loadDays();
      closeAutofillPopup();
    } catch (e) {
      setFieldError("_", "Erreur inattendue : " + e.message);
      console.error(e);
    } finally {
      showSpinner(false);
    }
  }

  return (
    <>
      <Popup visible={autofillPopupVisible} onDismiss={closeAutofillPopup}>
        {month && (
          <Formik
            innerRef={autofillFormRef}
            initialValues={{
              weekdayTemplate: null,
              weekendTemplate: null,
              _: null,
            }}
            validate={(values) => {
              const errors = {};
              return errors;
            }}
            onSubmit={autofill}
          >
            {({ isSubmitting }) => (
              <Form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "20px",
                }}
              >
                <h3>Remplir le mois</h3>
                <div>
                  <label htmlFor="month">Mois</label>
                  <Field
                    type="text"
                    name="month"
                    id="month"
                    readOnly={true}
                    value={monthName(month.month) + " " + month.year}
                    style={{ width: "350px", display: "block" }}
                  />
                </div>
                <div>
                  <label htmlFor="weekdayTemplate">Jour de semaine</label>
                  <Field
                    as={TemplateDaySelector}
                    id="weekdayTemplate"
                    name="weekdayTemplate"
                    style={{ width: "350px" }}
                  />
                </div>
                <div>
                  <label htmlFor="weekendTemplate">Fin de semaine</label>
                  <Field
                    as={TemplateDaySelector}
                    id="weekendTemplate"
                    name="weekendTemplate"
                    style={{ width: "350px" }}
                  />
                </div>
                <div style={{ marginTop: "10px" }}>
                  <button type="button" onClick={closeAutofillPopup}>
                    Annuler
                  </button>
                  &nbsp;
                  <button
                    className="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Confirmer
                  </button>
                  <ErrorMessage name="_" component="div" className="err" />
                </div>
              </Form>
            )}
          </Formik>
        )}
      </Popup>
      <TitleBar>
        <TitleBarText>Calendrier</TitleBarText>
      </TitleBar>
      {month && (
        <Content>
          <div className="calendar-header">
            <button onClick={() => changeMonth(-1)}>
              <img src="left.png" alt="précédent" className="btnimg-solo" />
            </button>
            <h2 style={{ width: "220px", textAlign: "center" }}>
              {monthName(month.month) + " " + month.year}
            </h2>
            <button onClick={() => changeMonth(1)}>
              <img src="right.png" alt="suivant" className="btnimg-solo" />
            </button>
            <button onClick={openAutofillPopup} style={{ marginLeft: "20px" }}>
              <img src="apply.png" alt="remplir" className="btnimg" />
              Remplir
            </button>
          </div>
          <table className="calendar" cellSpacing="5">
            <thead>
              <tr>
                <th>lundi</th>
                <th>mardi</th>
                <th>mercredi</th>
                <th>jeudi</th>
                <th>vendredi</th>
                <th>samedi</th>
                <th>dimanche</th>
              </tr>
            </thead>
            <tbody className="noselect">
              {splitArray(cells, 6).map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <td
                      onClick={() => {
                        if (month.month - 1 === cell.date.getUTCMonth())
                          navigate("/day/" + dateToYYYYMMDD(cell.date, true));
                      }}
                      key={cellIndex}
                      className={
                        (month.month - 1 === cell.date.getUTCMonth()
                          ? "calendar-active"
                          : "calendar-inactive") +
                        " " +
                        (cellIndex < 5
                          ? "calendar-weekday"
                          : "calendar-weekend") +
                        " " +
                        (cell.date.getTime() ===
                        dateOnly(new Date(), false).getTime()
                          ? "calendar-today"
                          : "")
                      }
                    >
                      <div className="calendar-cell">
                        <div className="calendar-cell-top">
                          <div
                            className="calendar-cell-date"
                            style={{
                              color: cell.details
                                ? rgbaFromColorCode(cell.details.color, false)
                                : "white",
                            }}
                          >
                            {cell.date.getUTCDate()}
                          </div>
                          {cell.details && (
                            <>
                              {cell.details.icon ? (
                                <img
                                  src={session.getIconURL(cell.details.icon)}
                                  alt="icône"
                                  className="calendar-cell-icon"
                                  draggable={false}
                                />
                              ) : (
                                <div className="calendar-cell-icon"></div>
                              )}
                            </>
                          )}
                        </div>
                        <div className="calendar-cell-bottom">
                          {cell.details &&
                            cell.details.events.map(
                              (event, eventIndex) =>
                                eventIndex < 3 &&
                                (event.icon ? (
                                  <img
                                    key={event.id}
                                    className="calendar-event"
                                    alt="évènement"
                                    src={session.getIconURL(event.icon)}
                                    draggable={false}
                                  />
                                ) : (
                                  <div
                                    key={event.id}
                                    className="calendar-event"
                                  >
                                    {event.name[0]}
                                  </div>
                                ))
                            )}
                        </div>
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </Content>
      )}
    </>
  );
}
