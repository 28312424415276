import { rgbaFromColorCode } from "../utils.mjs";

export default function DayName({ color, name, Elem = "span" }) {
  return (
    <Elem
      style={{
        backgroundColor: rgbaFromColorCode(color, true),
        color: rgbaFromColorCode(color, false),
      }}
    >
      {name}
    </Elem>
  );
}
