import { getIn, useFormikContext } from "formik";
import "./TemplateDaySelector.css";
import { useCallback, useEffect, useState } from "react";
import session from "../../Session.service";
import Popup from "../Popup/Popup";
import DayName from "../DayName";
import { showSpinner } from "../Spinner/Spinner";

export default function TemplateDaySelector(props) {
  const { setFieldValue, values } = useFormikContext();
  const [name, setName] = useState("");
  const fieldValue = getIn(values, props.name);
  const [popupVisible, setPopupVisible] = useState(false);
  const [templateDays, setTemplateDays] = useState(null);

  function changeValue(val) {
    setFieldValue(props.name, val);
    setPopupVisible(false);
  }

  const refreshTemplateName = useCallback(async () => {
    if (!fieldValue) {
      setName("");
    } else {
      const tday = await session.getTemplateDay(fieldValue);
      setName(tday.name);
    }
  }, [setName, fieldValue]);

  useEffect(() => {
    refreshTemplateName();
  }, [fieldValue, refreshTemplateName]);

  async function openPopup() {
    showSpinner(true);
    setTemplateDays(await session.getTemplateDays());
    setPopupVisible(true);
    showSpinner(false);
  }

  function closePopup() {
    setPopupVisible(false);
  }

  return (
    <>
      <Popup visible={popupVisible} onDismiss={closePopup}>
        <h3>Sélectionner un modèle de journée</h3>

        {templateDays && (
          <div className="tdaysel-list">
            {templateDays.map((tday) => (
              <TemplateDay
                key={tday.id}
                icon={tday.icon}
                onClick={() => changeValue(tday.id)}
              >
                <DayName color={tday.color} name={tday.name} />
              </TemplateDay>
            ))}
          </div>
        )}

        <button type="button" onClick={closePopup}>
          Annuler
        </button>
      </Popup>
      <div className="tdaysel-container" style={{ ...props.style }}>
        <input
          className="tdaysel-input"
          autoComplete="off"
          type="text"
          readOnly={true}
          id={props.id}
          value={name}
        />
        <button type="button" className="tdaysel-rightbtn" onClick={openPopup}>
          <img src="/search.png" alt="sélectionner" className="btnimg-solo" />
        </button>
        <button
          type="button"
          className="tdaysel-rightbtn"
          onClick={() => changeValue(null)}
        >
          <img src="/cross.png" alt="effacer" className="btnimg-solo" />
        </button>
      </div>
    </>
  );
}

function TemplateDay({ icon, children, onClick }) {
  return (
    <div className="tdaysel-item noselect" onClick={onClick}>
      {icon ? (
        <img
          draggable={false}
          src={session.getIconURL(icon)}
          alt=""
          className="tdaysel-item-icon"
        />
      ) : (
        <div className="tdaysel-item-icon" />
      )}
      <div className="tdaysel-item-name">{children}</div>
    </div>
  );
}
