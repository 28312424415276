import "./Content.css";

export default function ContentCentered({ children, style, className }) {
  return (
    <div className="content-centered">
      <div
        style={style}
        className={"content-centered-box " + (className ? className : "")}
      >
        {children}
      </div>
    </div>
  );
}
