import { useFormikContext } from "formik";
import "./IconSelector.css";
import session from "../../Session.service";
import Popup from "../Popup/Popup";
import { useState } from "react";
import { showSpinner } from "../Spinner/Spinner";

export default function IconSelector(props) {
  const { setFieldValue } = useFormikContext();
  const [popupVisible, setPopupVisible] = useState(false);
  const [icons, setIcons] = useState();

  async function openPopup() {
    showSpinner(true);
    setIcons(await session.getAllIcons());
    setPopupVisible(true);
    showSpinner(false);
  }

  function closePopup() {
    setPopupVisible(false);
  }

  function iconSelected(id) {
    setFieldValue(props.name, id);
    setPopupVisible(false);
  }

  const imgSize = props.compact ? "65px" : "75px";

  return (
    <>
      <Popup visible={popupVisible} onDismiss={closePopup}>
        <h3>Sélectionner une icône</h3>

        {icons && (
          <>
            {icons.length <= 0 ? (
              <div>Aucune icône disponible actuellement.</div>
            ) : (
              <div className="icosel-list noselect">
                {icons.map((icon) => (
                  <img
                    draggable={false}
                    onClick={() => iconSelected(icon)}
                    key={icon}
                    src={session.getIconURL(icon)}
                    alt="icône"
                    className="icosel-list-item"
                  />
                ))}
              </div>
            )}
          </>
        )}

        <button type="button" onClick={closePopup}>
          Annuler
        </button>
      </Popup>
      <div className="icosel-container">
        {props.value ? (
          <img
            className="icosel-icon"
            src={session.getIconURL(props.value)}
            alt="Icône sélectionnée"
            style={{ height: imgSize, width: imgSize }}
          />
        ) : (
          <div
            className="icosel-icon"
            style={{ height: imgSize, width: imgSize }}
          ></div>
        )}

        <div
          style={{
            display: "flex",
            flexDirection: props.compact ? "column" : "row",
            gap: "5px",
          }}
        >
          <button
            type="button"
            onClick={openPopup}
            style={{ height: props.compact ? "30px" : "36px" }}
          >
            <img src="/search.png" alt="Rechercher" className="btnimg" />
            Sélectionner...
          </button>

          {props.value && (
            <button
              type="button"
              onClick={() => iconSelected(null)}
              style={{ height: props.compact ? "30px" : "36px" }}
            >
              <img src="/trash.png" alt="Effacer" className="btnimg" />
              Retirer
            </button>
          )}
        </div>
      </div>
    </>
  );
}
