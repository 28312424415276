import Content from "../../Content/Content";
import "./Stars.css";
import { showSpinner } from "../../Spinner/Spinner";
import { useEffect, useState } from "react";
import session from "../../../Session.service";
import TitleBar, { TitleBarText } from "../../TitleBar/TitleBar";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { DropMenuBtn, DropMenuItem } from "../../DropMenu/DropMenu";
import { dateToYYYYMMDD } from "../../../utils.mjs";

export default function Stars() {
  const [starsInfo, setStarsInfo] = useState(null);

  async function loadStarsInfo() {
    showSpinner(true);
    setStarsInfo(await session.getStarsExt());
    showSpinner(false);
  }

  useEffect(() => {
    (async () => {
      loadStarsInfo();
    })();
  }, []);

  async function onSubmit(values, { setFieldError, resetForm }) {
    showSpinner(true);
    try {
      await session.addStars(values.amount);
      await loadStarsInfo();
      resetForm();
    } catch (e) {
      setFieldError("amount", e.message);
    } finally {
      showSpinner(false);
    }
  }

  async function deleteItem(id) {
    showSpinner(true);
    await session.deleteStars(id);
    await loadStarsInfo();
    showSpinner(false);
  }

  return (
    <>
      <TitleBar>
        <TitleBarText>Étoiles</TitleBarText>
      </TitleBar>
      {starsInfo && (
        <Content>
          <h1>
            <img
              src="/star.png"
              alt="étoiles"
              style={{
                width: "25px",
                height: "25px",
              }}
            />{" "}
            {starsInfo.total}
          </h1>
          <Formik
            initialValues={{ amount: "" }}
            validate={(values) => {
              const errors = {};
              if (isNaN(values.amount)) {
                errors.amount = "Vous devez entrer un nombre valide.";
              }
              return errors;
            }}
            onSubmit={onSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <div>
                  <span>Ajouter / retirer des étoiles :</span>
                  <Field
                    type="number"
                    name="amount"
                    placeholder="Quantité"
                    autoFocus={true}
                    style={{ marginLeft: "5px", marginRight: "5px" }}
                  />
                  <button
                    className="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Appliquer
                  </button>
                </div>
                <div className="err" style={{ height: "30px" }}>
                  <ErrorMessage name="amount" />
                </div>
              </Form>
            )}
          </Formik>
          <h3>Historique</h3>
          <div className="stars-history">
            {starsInfo.history.map((item) => (
              <div key={item.id} className="stars-history-item">
                <img
                  className="stars-history-item-icon"
                  src={item.amount >= 0 ? "/star.png" : "/star-red.png"}
                  alt="étoiles"
                />
                <div className="stars-history-item-amount">
                  <span
                    style={{
                      marginLeft: "10px",
                      color: item.amount >= 0 ? "#eee" : "#e77",
                    }}
                  >
                    {item.amount}
                  </span>
                </div>
                <div className="stars-history-item-source">
                  {item.routine
                    ? "routine" +
                      (item.routine.id
                        ? " : " +
                          item.routine.name +
                          " (" +
                          dateToYYYYMMDD(new Date(item.routine.date), true) +
                          ")"
                        : " (introuvable)")
                    : "manuel"}
                </div>
                <div className="stars-history-item-date">
                  {dateToYYYYMMDD(new Date(item.datetime))}
                </div>
                <DropMenuBtn className="stars-history-item-menu">
                  <>
                    <DropMenuItem
                      label="Supprimer"
                      icon="trash.png"
                      onClick={() => {
                        deleteItem(item.id);
                      }}
                    />
                  </>
                </DropMenuBtn>
              </div>
            ))}
          </div>
        </Content>
      )}
    </>
  );
}
