import "./DayDisplay.css";
import DayName from "../DayName";
import session from "../../Session.service";
import { useEffect, useRef, useState } from "react";
import {
  currentTimeUnit,
  dateOnly,
  timeUnitToTime,
  useForceUpdate,
} from "../../utils.mjs";
import TimeTimer from "../TimeTimer";
import { DropMenuBtn, DropMenuItem } from "../DropMenu/DropMenu";
import { showSpinner } from "../Spinner/Spinner";
import Popup from "../Popup/Popup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import TemplateDaySelector from "../TemplateDaySelector/TemplateDaySelector";
import TemplateEventSelector from "../TemplateEventSelector/TemplateEventSelector";
import Toggle from "../Toggle/Toggle";
import IconSelector from "../IconSelector/IconSelector";
import TimeSelector from "../TimeSelector/TimeSelector";
import DurationSelector from "../DurationSelector/DurationSelector";

export default function DayDisplay({ day, onNeedUpdate }) {
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    const interval = setInterval(forceUpdate, 1000);
    return () => clearTimeout(interval);
  });

  function getDateFromDay() {
    return new Date(Date.parse(day.date));
  }

  async function deleteItem(item) {
    if (item.type !== "event") return;
    showSpinner(true);
    await session.deleteEvent(item.id);
    onNeedUpdate();
    showSpinner(false);
  }

  //#region Reset Popup

  const [resetPopupVisible, setResetPopupVisible] = useState(false);

  function openResetPopup() {
    setResetPopupVisible(true);
  }
  async function resetDay() {
    showSpinner(true);
    await session.resetDay(getDateFromDay());
    closeResetPopup();
    onNeedUpdate();
    showSpinner(false);
  }
  function closeResetPopup() {
    setResetPopupVisible(false);
  }

  const resetPopup = (
    <Popup visible={resetPopupVisible} onDismiss={closeResetPopup}>
      <h3>Remise à zéro</h3>
      <p>Voulez-vous vraiment remettre à zéro cette journée?</p>
      <p>
        Les routines les identificateurs de la journée seront effacés,
        <br />
        mais les évènements resteront intact.
      </p>

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
          gap: "10px",
        }}
      >
        <button onClick={closeResetPopup}>Annuler</button>
        <button onClick={resetDay} className="danger">
          R.À.Z.
        </button>
      </div>
    </Popup>
  );

  //#endregion

  //#region Template Popup

  const [templatePopupVisible, setTemplatePopupVisible] = useState(false);
  const templateFormRef = useRef();

  function openTemplatePopup() {
    setTemplatePopupVisible(true);
  }
  async function applyTemplate(values, { setFieldError }) {
    showSpinner(true);
    try {
      await session.dayApplyTemplate(getDateFromDay(), values.template);
      onNeedUpdate();
      closeTemplatePopup();
    } catch (e) {
      setFieldError("_", "Erreur inattendue : " + e.message);
      console.error(e);
    } finally {
      showSpinner(false);
    }
  }
  function closeTemplatePopup() {
    setTemplatePopupVisible(false);
    templateFormRef.current.resetForm();
  }

  const templatePopup = (
    <Popup visible={templatePopupVisible} onDismiss={closeTemplatePopup}>
      <Formik
        innerRef={templateFormRef}
        initialValues={{
          template: null,
          _: null,
        }}
        validate={(values) => {
          const errors = {};
          if (!values.template) {
            errors.template = "vous devez sélectionner un modèle.";
          }
          return errors;
        }}
        onSubmit={applyTemplate}
      >
        {({ isSubmitting }) => (
          <Form
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "20px",
            }}
          >
            <h3>Appliquer un modèle</h3>
            <div>
              <label htmlFor="template">Modèle de journée</label>
              <Field
                as={TemplateDaySelector}
                id="template"
                name="template"
                style={{ width: "350px" }}
              />
              <ErrorMessage name="template" component="div" className="err" />
            </div>
            <div style={{ marginTop: "10px" }}>
              <button type="button" onClick={closeTemplatePopup}>
                Annuler
              </button>
              &nbsp;
              <button className="primary" type="submit" disabled={isSubmitting}>
                Appliquer
              </button>
              <ErrorMessage name="_" component="div" className="err" />
            </div>
          </Form>
        )}
      </Formik>
    </Popup>
  );

  //#endregion

  //#region Event Popup

  const [eventPopupVisible, setEventPopupVisible] = useState(false);
  const eventFormRef = useRef();
  function openEventPopup() {
    setEventPopupVisible(true);
  }
  async function createEvent(values, { setFieldError }) {
    showSpinner(true);
    try {
      const data = { time: values.time, date: getDateFromDay() };
      if (values.fromTemplate) {
        data.template = values.template;
      } else {
        data.name = values.name;
        data.icon = values.icon;
        data.time = values.time;
        data.duration = values.duration;
      }
      await session.createEvent(data);
      onNeedUpdate();
      closeEventPopup();
    } catch (e) {
      setFieldError("_", "Erreur inattendue : " + e.message);
      console.error(e);
    } finally {
      showSpinner(false);
    }
  }
  function closeEventPopup() {
    setEventPopupVisible(false);
    eventFormRef.current.resetForm();
  }

  const eventPopup = (
    <Popup visible={eventPopupVisible} onDismiss={closeEventPopup}>
      <Formik
        innerRef={eventFormRef}
        initialValues={{
          fromTemplate: true,
          template: null,
          time: 48,
          duration: 4,
          name: "",
          icon: null,
          _: null,
        }}
        validate={(values) => {
          const errors = {};
          if (values.fromTemplate && !values.template) {
            errors.template = "requis";
          }
          if (!values.fromTemplate && !values.name) {
            errors.template = "requis";
          }
          return errors;
        }}
        onSubmit={createEvent}
      >
        {({ isSubmitting, values }) => (
          <Form
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "20px",
            }}
          >
            <h3>Nouvel évènement</h3>

            <div>
              <Field
                as={Toggle}
                id="fromTemplate"
                name="fromTemplate"
                label="À partir d'un modèle"
              />
            </div>

            {values.fromTemplate && (
              <div>
                <label htmlFor="template">Modèle d'évènement</label>
                <Field
                  as={TemplateEventSelector}
                  id="template"
                  name="template"
                  style={{ width: "350px" }}
                />
                <ErrorMessage name="template" component="div" className="err" />
              </div>
            )}

            {!values.fromTemplate && (
              <div>
                <label htmlFor="name">Nom</label>
                <Field
                  autoComplete="off"
                  type="text"
                  name="name"
                  id="name"
                  style={{ width: "350px", display: "block" }}
                  placeholder="ex: Balade en vélo"
                />
                <ErrorMessage name="name" component="div" className="err" />
              </div>
            )}

            {!values.fromTemplate && (
              <div>
                <label htmlFor="icon">Icône</label>
                <Field as={IconSelector} compact={true} id="icon" name="icon" />
              </div>
            )}

            <div>
              <label htmlFor="time">Début</label>
              <Field
                as={TimeSelector}
                id="time"
                name="time"
                style={{ width: "350px" }}
              />
              <ErrorMessage name="time" component="div" className="err" />
            </div>

            {!values.fromTemplate && (
              <div>
                <label htmlFor="duration">Durée</label>
                <Field
                  as={DurationSelector}
                  id="duration"
                  name="duration"
                  style={{ width: "350px" }}
                />
                <ErrorMessage name="duration" component="div" className="err" />
              </div>
            )}

            <div style={{ marginTop: "10px" }}>
              <button type="button" onClick={closeEventPopup}>
                Annuler
              </button>
              &nbsp;
              <button className="primary" type="submit" disabled={isSubmitting}>
                Ajouter
              </button>
              <ErrorMessage name="_" component="div" className="err" />
            </div>
          </Form>
        )}
      </Formik>
    </Popup>
  );

  //#endregion Event Popup

  //#region Render

  if (!day) return <></>;

  let items = [];
  if (day) {
    for (const routine of day.routines) {
      items.push({
        type: "routine",
        ...routine,
      });
    }
    for (const event of day.events) {
      items.push({
        type: "event",
        ...event,
      });
    }
  }
  items = items.sort((a, b) => a.time - b.time);

  function relativeTime(current, relative) {
    if (current === relative) return "Maintenant";
    const minutes = Math.round(Math.abs(relative - current) * 15);
    let phrase = "";
    if (minutes > 60) {
      phrase = Math.round(minutes / 60) + " heure(s)";
    } else {
      phrase = minutes + " minutes(s)";
    }
    return (relative < current ? "Il y a " : "Dans ") + phrase;
  }

  const currTime = currentTimeUnit(true);
  const today = dateOnly(new Date());
  const date = getDateFromDay();
  const diff = Math.round(
    (date.getTime() - today.getTime()) / 1000 / 60 / 60 / 24
  );
  let foundNext = false;
  items.forEach((item) => {
    item.next = false;
    item.today = false;
    if (diff < 0) {
      item.stage = "passed";
      return;
    }
    if (diff > 0) {
      item.stage = "future";
      return;
    }
    item.today = true;
    if (currTime < item.time) {
      if (!foundNext) {
        item.next = true;
        foundNext = true;
      }
      item.stage = "future";
      item.relative = relativeTime(currTime, item.time);
    } else if (currTime < item.time + item.duration) {
      if (!foundNext) {
        item.next = true;
        foundNext = true;
      }
      item.stage = "inprogress";
      item.progress = Math.round(
        ((currTime - item.time) / item.duration) * 100
      );
    } else {
      item.stage = "passed";
      item.relative = relativeTime(currTime, item.time + item.duration);
    }
  });

  //#endregion

  return (
    <>
      {resetPopup}
      {templatePopup}
      {eventPopup}
      <div className="daydisplay-actions">
        <button type="button" onClick={openEventPopup}>
          <img src="/plus.png" alt="appliquer" className="btnimg" />
          Nouvel évènement
        </button>
        <button type="button" onClick={openTemplatePopup}>
          <img src="/apply.png" alt="appliquer" className="btnimg" />
          Appliquer un modèle
        </button>
        <button type="button" onClick={openResetPopup}>
          <img src="/trash.png" alt="remise à zéro" className="btnimg" />
          Remise à zéro
        </button>
      </div>
      <div className="daydisplay-container">
        {(day.name || day.icon) && (
          <h1>
            {day.icon && (
              <img
                draggable={false}
                style={{
                  height: "45px",
                  width: "45px",
                  marginRight: "15px",
                  verticalAlign: "text-bottom",
                }}
                src={session.getIconURL(day.icon)}
                alt=""
              />
            )}
            <DayName name={day.name} color={day.color} />
          </h1>
        )}
        {items.length > 0 ? (
          items.map((item) => (
            <DayDisplayItem
              onNeedUpdate={onNeedUpdate}
              key={item.id}
              item={item}
              onDelete={() => deleteItem(item)}
            />
          ))
        ) : (
          <p>Il n'y a pas de routines ni d'évènements pour cette journée.</p>
        )}
      </div>
    </>
  );
}

function DayDisplayItem({ item, onNeedUpdate, onDelete }) {
  let cssClass =
    item.type === "routine" ? "daydisplay-routine" : "daydisplay-event";
  if (item.stage === "inprogress") cssClass += " daydisplay-inprogress";
  if (item.stage === "passed") cssClass += " daydisplay-passed";
  if (item.next) cssClass += " daydisplay-next";

  async function setRoutineStatus(status) {
    showSpinner(true);
    await session.setRoutineStatus(item.id, status);
    onNeedUpdate();
  }
  async function markAllTasks(done) {
    showSpinner(true);
    for (const task of item.tasks) {
      await session.setTaskStatus(task.id, done);
    }
    onNeedUpdate();
  }

  return (
    <>
      <div className={"noselect daydisplay-item " + cssClass}>
        <div className="daydisplay-item-header">
          {item.icon ? (
            <img
              draggable={false}
              src={session.getIconURL(item.icon)}
              className="daydisplay-item-icon"
              alt=""
            />
          ) : (
            <div className="daydisplay-item-icon" />
          )}
          <div className="daydisplay-item-details">
            <p style={{ fontSize: "14px", color: "#aaa" }}>
              {timeUnitToTime(item.time)}
            </p>
            <h3 style={{ fontSize: "18px" }}>{item.name}</h3>
          </div>
          <div className="daydisplay-item-controls">
            {item.stage === "inprogress" && (
              <div className="daydisplay-item-indicator">
                <TimeTimer size={40} value={item.progress} />
              </div>
            )}
            {item.today && item.stage !== "inprogress" && (
              <div className="daydisplay-item-relative">{item.relative}</div>
            )}
            <DropMenuBtn>
              {item.type === "routine" && (
                <>
                  <DropMenuItem
                    label="Donner l'étoile"
                    icon="/star.png"
                    onClick={() => {
                      setRoutineStatus(1);
                    }}
                  />
                  <DropMenuItem
                    label="Enelver l'étoile"
                    icon="/star-red.png"
                    onClick={() => {
                      setRoutineStatus(2);
                    }}
                  />
                  <DropMenuItem
                    label='Marquer toutes les tâches comme "à faire"'
                    onClick={() => {
                      markAllTasks(false);
                    }}
                  />
                  <DropMenuItem
                    label='Marquer toutes les tâches comme "terminée"'
                    onClick={() => {
                      markAllTasks(true);
                    }}
                  />
                </>
              )}

              {item.type === "event" && (
                <>
                  <DropMenuItem
                    label="Supprimer"
                    icon="/trash.png"
                    onClick={() => {
                      onDelete();
                    }}
                  />
                </>
              )}
            </DropMenuBtn>
          </div>
        </div>
        {item.type === "routine" && (
          <div className="daydisplay-item-tasks">
            <img
              draggable={false}
              src={
                item.status === 0
                  ? "/star-gray.png"
                  : item.status === 1
                  ? "/star.png"
                  : "/star-red.png"
              }
              alt="star"
              className="daydisplay-item-star"
            />
            {item.tasks.map((task) => (
              <div
                key={task.id}
                className={
                  "daydisplay-item-task " +
                  (task.done
                    ? "daydisplay-item-task-done"
                    : item.stage === "passed"
                    ? "daydisplay-item-task-missed"
                    : "")
                }
              >
                {task.icon && (
                  <img
                    draggable={false}
                    src={session.getIconURL(task.icon)}
                    className="daydisplay-item-task-icon"
                    alt=""
                  />
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
}
