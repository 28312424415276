import { useEffect, useRef, useState } from "react";
import "./Popup.css";

export default function Popup({ visible, children, onDismiss }) {
  const wrapperRef = useRef(null);
  const [neverUsed, setNeverUsed] = useState(true);

  if (visible && neverUsed) setNeverUsed(false);

  if (!visible && !neverUsed) {
    setTimeout(() => {
      if (!visible && !neverUsed) {
        setNeverUsed(true);
      }
    }, 100);
  }

  let cssClass = visible ? "popup-show" : "popup-hide";
  const cssStyle = neverUsed ? { display: "none" } : {};

  useEffect(() => {
    function handleDocumentClick(evt) {
      if (wrapperRef.current && !wrapperRef.current.contains(evt.target)) {
        onDismiss();
      }
    }
    document.addEventListener("mousedown", handleDocumentClick);
    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, [onDismiss, wrapperRef]);

  return (
    <div className={"popup-overlay " + cssClass} style={cssStyle}>
      <div ref={wrapperRef} className="popup-wrapper">
        {children}
      </div>
    </div>
  );
}
