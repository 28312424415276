import { useNavigate, useParams } from "react-router-dom";
import "./Day.css";
import TitleBar, {
  TitleBarBackBtn,
  TitleBarText,
} from "../../TitleBar/TitleBar";
import Content from "../../Content/Content";
import { monthName } from "../../../utils.mjs";
import DayDisplay from "../../DayDisplay/DayDisplay";
import { showSpinner } from "../../Spinner/Spinner";
import { useCallback, useEffect, useState } from "react";
import session from "../../../Session.service";

export default function Day() {
  const { date: pDate } = useParams();
  const navigate = useNavigate();
  const [day, setDay] = useState(null);

  const getDateFromParam = useCallback(() => {
    const y = pDate.split("-")[0];
    const m = pDate.split("-")[1];
    const d = pDate.split("-")[2];
    if (isNaN(y) || isNaN(m) || isNaN(d)) return null;
    const date = new Date(Date.UTC(y, m - 1, d));
    if (!date) return null;
    return date;
  }, [pDate]);

  function backToMonth() {
    const year_month = pDate.split("-")[0] + "-" + pDate.split("-")[1];
    navigate("/month?position=" + year_month);
  }

  const loadDay = useCallback(async () => {
    if (!getDateFromParam()) return;
    showSpinner(true);
    setDay(await session.getDay(getDateFromParam()));
    showSpinner(false);
  }, [getDateFromParam]);
  useEffect(() => {
    loadDay();
  }, [loadDay]);

  const date = getDateFromParam();
  return (
    <>
      <TitleBar>
        <TitleBarBackBtn onClick={backToMonth} />
        <TitleBarText>
          {date.getUTCDate() +
            " " +
            monthName(parseInt(date.getUTCMonth() + 1)) +
            " " +
            date.getUTCFullYear()}
        </TitleBarText>
      </TitleBar>
      <Content>
        <DayDisplay day={day} onNeedUpdate={loadDay} />
      </Content>
    </>
  );
}
