import { useEffect, useState } from "react";

import "./Today.css";

import { showSpinner } from "../../Spinner/Spinner";
import TitleBar, { TitleBarText } from "../../TitleBar/TitleBar";
import Content from "../../Content/Content";
import session from "../../../Session.service";
import DayDisplay from "../../DayDisplay/DayDisplay";

export default function Home() {
  const [day, setDay] = useState(null);

  async function loadDay() {
    showSpinner(true);
    const newDay = await session.getDay();
    setDay(newDay);
    showSpinner(false);
  }
  useEffect(() => {
    loadDay();
  }, []);

  return (
    <>
      <TitleBar>
        <TitleBarText>Aujourd'hui</TitleBarText>
      </TitleBar>
      {day && (
        <Content>
          <DayDisplay day={day} onNeedUpdate={loadDay} />
        </Content>
      )}
    </>
  );
}
