import "./Toggle.css";
import { useFormikContext } from "formik";

export default function Toggle(props) {
  const { setFieldValue } = useFormikContext();

  function changeValue(val) {
    setFieldValue(props.name, val);
  }

  return (
    <div className="toggle-container noselect">
      <label className="toggle" style={{ ...props.style }}>
        <input
          type="checkbox"
          checked={props.value}
          id={props.id}
          onChange={(evt) => {
            changeValue(evt.target.checked);
          }}
        />
        <span className="toggle-slider"></span>
      </label>
      <div onClick={() => changeValue(!props.value)} className="toggle-label">
        {props.label}
      </div>
    </div>
  );
}
