import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";

import "./App.css";

import Login from "./components/pages/Login/Login";
import Today from "./components/pages/Today/Today";
import NotFound from "./components/pages/NotFound/NotFound";
import Month from "./components/pages/Month/Month";
import Templates from "./components/pages/Templates/Templates";
import Icons from "./components/pages/Icons/Icons";

import session from "./Session.service";
import SideMenu from "./components/SideMenu/SideMenu";
import { useForceUpdate } from "./utils.mjs";
import Stars from "./components/pages/Stars/Stars";
import TemplateEvent from "./components/pages/Templates/TemplateEvent/TemplateEvent";
import TemplateDay from "./components/pages/Templates/TemplateDay/TemplateDay";
import Day from "./components/pages/Day/Day";

export default function App() {
  const forceUpdate = useForceUpdate();

  function onLogin() {
    forceUpdate();
  }

  function RouteGuard({ auth = false, redirect = "/" }) {
    if (auth) return <Outlet />;
    else return <Navigate to={redirect} replace />;
  }

  const GuardedRoutes = (
    <>
      <Route exact path="/today" element={<Today />} />
      <Route exact path="/month" element={<Month />} />
      <Route exact path="/day/:date" element={<Day />} />
      <Route exact path="/stars" element={<Stars />} />
      <Route exact path="/icons" element={<Icons />} />

      <Route exact path="/templates" element={<Templates />} />
      <Route
        exact
        path="/templates/days"
        element={<Navigate to="/templates" />}
      />
      <Route
        exact
        path="/templates/events"
        element={<Navigate to="/templates" />}
      />
      <Route exact path="/templates/events/:id" element={<TemplateEvent />} />
      <Route exact path="/templates/days/:id" element={<TemplateDay />} />

      <Route exact path="/" element={<Navigate to="/today" />} />
      <Route exact path="*" element={<NotFound />} />
    </>
  );

  const UnguardedRoutes = (
    <>
      <Route exact path="/login" element={<Login onLogin={onLogin} />} />
      <Route exact path="/" element={<Navigate to="/login" />} />
      <Route exact path="*" element={<Navigate to="/login" />} />
    </>
  );

  return (
    <BrowserRouter>
      <div className="wrapper">
        {session.isAuth && <SideMenu />}

        <div className="page">
          <Routes>
            <Route
              element={<RouteGuard auth={session.isAuth} redirect={"/login"} />}
            >
              {GuardedRoutes}
            </Route>
            <Route
              element={
                <RouteGuard auth={!session.isAuth} redirect={"/today"} />
              }
            >
              {UnguardedRoutes}
            </Route>
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
}
