import { Link, NavLink } from "react-router-dom";
import "./SideMenu.css";
import session from "../../Session.service";
import { showSpinner } from "../Spinner/Spinner";

export default function SideMenu() {
  function logout() {
    showSpinner(true);
    // Let spinner appear
    setTimeout(() => {
      session.forceLogout();
      window.location.reload();
    }, 500);
  }

  return (
    <>
      <div className="side-menu">
        <Link to="/" className="app-title noselect">
          <img className="app-title-logo" src="/logo128.png" alt="app logo" />
          <div className="app-title-text">Fostr</div>
        </Link>

        <NavLink className="side-menu-item" to="/today">
          Aujourd'hui
        </NavLink>
        <NavLink className="side-menu-item" to="/month">
          Calendrier
        </NavLink>
        <NavLink className="side-menu-item" to="/stars">
          Étoiles
        </NavLink>
        <NavLink className="side-menu-item" to="/templates">
          Modèles
        </NavLink>
        <NavLink className="side-menu-item" to="/icons">
          Icônes
        </NavLink>

        <div className="side-menu-spacer"></div>

        <Link onClick={logout} className="side-menu-item">
          Déconnexion
        </Link>

        <div
          style={{
            marginTop: "auto",
            fontSize: "12px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#333",
            color: "#aaa",
            padding: "10px 0",
            fontFamily: "SpaceMonoRegular",
          }}
        >
          <span>
            {process.env.REACT_APP_NAME} v{process.env.REACT_APP_VERSION}
          </span>
          <span>
            par <b>Jonathan Ashby</b>
          </span>
        </div>
      </div>
    </>
  );
}
