import { useNavigate } from "react-router-dom";
import "./NotFound.css";
import Content from "../../Content/Content";

export default function NotFound() {
  const navigate = useNavigate();

  function goToHome() {
    navigate("/today");
  }

  return (
    <Content style={{ padding: "50px 70px" }}>
      <h1>Introuvable</h1>
      <p>La page demandée n'existe pas.</p>
      <button onClick={goToHome}>Retour à l'accueil</button>
    </Content>
  );
}
