import { rgbaFromColorCode } from "../../utils.mjs";
import "./ColorSelector.css";
import { useFormikContext } from "formik";

const colors = [-3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

export default function ColorSelector(props) {
  const { setFieldValue } = useFormikContext();

  function changeValue(val) {
    setFieldValue(props.name, Math.min(Math.max(-3, val), 11));
  }

  return (
    <div className="colsel-container">
      {colors.map((color) => (
        <div
          key={color}
          className={
            "colsel-item" +
            (color === props.value ? " colsel-item-selected" : "")
          }
          style={{
            backgroundColor: rgbaFromColorCode(color, false),
          }}
          onClick={() => changeValue(color)}
        ></div>
      ))}
    </div>
  );
}
