import { useState } from "react";
import "./Spinner.css";

let changeSpinnerState = null;
export function showSpinner(show) {
  changeSpinnerState(show);
}

export default function Spinner() {
  const [visible, setVisible] = useState(false);
  changeSpinnerState = setVisible;

  const [neverUsed, setNeverUsed] = useState(true);
  if (visible && neverUsed) setNeverUsed(false);

  const cssStyle = neverUsed ? { display: "none" } : {};

  return (
    <div
      className={
        "spinner-overlay " +
        (visible ? "spinner-overlay-show " : "spinner-overlay-hide ")
      }
      style={cssStyle}
    >
      <img
        className="spinner-indicator noselect"
        src="/spinner.png"
        alt="loading"
      />
    </div>
  );
}
