import Content from "../../Content/Content";
import "./Templates.css";
import { showSpinner } from "../../Spinner/Spinner";
import { useEffect, useState } from "react";
import session from "../../../Session.service";
import TitleBar, { TitleBarText } from "../../TitleBar/TitleBar";
import DayName from "../../DayName";
import { DropMenuBtn, DropMenuItem } from "../../DropMenu/DropMenu";
import Popup from "../../Popup/Popup";
import { useNavigate } from "react-router-dom";

export default function Templates() {
  const [templateDays, setTemplateDays] = useState(null);
  const [templateEvents, setTemplateEvents] = useState(null);
  const [deletePopupVisible, setDeletePopupVisible] = useState(false);
  const [templateToDelete, setTemplateToDelete] = useState(null);

  const navigate = useNavigate();

  async function loadLists() {
    showSpinner(true);
    setTemplateDays(await session.getTemplateDays());
    setTemplateEvents(await session.getTemplateEvents());
    showSpinner(false);
  }

  useEffect(() => {
    (async () => {
      loadLists();
    })();
  }, []);

  function openDeletePopup(template) {
    setTemplateToDelete(template);
    setDeletePopupVisible(true);
  }

  function closeDeletePopup() {
    setDeletePopupVisible(false);
  }

  async function deleteTemplate() {
    showSpinner(true);

    if (templateToDelete) {
      if (templateToDelete.type === "day") {
        await session.deleteTemplateDay(templateToDelete.id);
      } else if (templateToDelete.type === "event") {
        await session.deleteTemplateEvent(templateToDelete.id);
      }
    }

    closeDeletePopup();
    await loadLists();
    showSpinner(false);
  }

  async function applyTemplateEvent(id) {
    showSpinner(true);
    await session.applyTemplateEvent(id);
    showSpinner(false);
  }
  async function applyTemplateDay(id) {
    showSpinner(true);
    await session.applyTemplateDay(id);
    showSpinner(false);
  }

  return (
    <>
      <Popup visible={deletePopupVisible} onDismiss={closeDeletePopup}>
        <h3>Suppression</h3>
        <p>Voulez-vous vraiment supprimer ce modèle?</p>

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            gap: "10px",
          }}
        >
          <button onClick={closeDeletePopup}>Annuler</button>
          <button onClick={deleteTemplate} className="danger">
            Supprimer
          </button>
        </div>
      </Popup>
      <TitleBar>
        <TitleBarText>Modèles</TitleBarText>
      </TitleBar>
      {templateDays && templateEvents && (
        <Content>
          <div className="templates-header">
            <h3>Modèles de journée</h3>
            <button
              type="button"
              onClick={() => navigate("/templates/days/new")}
            >
              <img src="/plus.png" alt="ajouter" className="btnimg" />
              Nouveau
            </button>
          </div>
          <div className="templates-list">
            {templateDays.map((day) => (
              <Template
                key={day.id}
                icon={day.icon}
                onEdit={() => navigate("/templates/days/" + day.id)}
                onDelete={() => openDeletePopup({ type: "day", ...day })}
                onApply={() => applyTemplateDay(day.id)}
              >
                <DayName name={day.name} color={day.color} />
              </Template>
            ))}
          </div>

          <div className="templates-header">
            <h3>Modèles d'évènement</h3>
            <button
              type="button"
              onClick={() => navigate("/templates/events/new")}
            >
              <img src="/plus.png" alt="ajouter" className="btnimg" />
              Nouveau
            </button>
          </div>
          <div className="templates-list">
            {templateEvents.map((event) => (
              <Template
                key={event.id}
                icon={event.icon}
                onEdit={() => navigate("/templates/events/" + event.id)}
                onDelete={() => openDeletePopup({ type: "event", ...event })}
                onApply={() => applyTemplateEvent(event.id)}
              >
                {event.name}
              </Template>
            ))}
          </div>
        </Content>
      )}
    </>
  );
}

function Template({ icon, children, onEdit, onDelete, onApply }) {
  return (
    <div className="templates-item noselect">
      <div className="templates-item-details" onClick={onEdit}>
        {icon ? (
          <img
            draggable={false}
            src={session.getIconURL(icon)}
            alt=""
            className="templates-item-icon"
          />
        ) : (
          <div className="templates-item-icon" />
        )}
        <div className="templates-item-name">{children}</div>
      </div>
      <DropMenuBtn className="templates-item-menu">
        <>
          <DropMenuItem label="Modifier" icon="edit.png" onClick={onEdit} />
          <DropMenuItem label="Appliquer" icon="apply.png" onClick={onApply} />
          <DropMenuItem label="Supprimer" icon="trash.png" onClick={onDelete} />
        </>
      </DropMenuBtn>
    </div>
  );
}
